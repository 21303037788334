import React from 'react'

const About = () => {
  return (
    <div className='w-full flex flex-col items-start pt-1'>
        <h1 className='text-[#eeeeee] text-sm tracking-[0.02em]'>About</h1>
        <h2 className='text-[#bbbbbb] text-sm mt-1 tracking-[0.01em]'>Web Developer, Tech Enthusiast.</h2>
        <h2 className='text-[#bbbbbb] text-sm tracking-[0.01em] mt-3'>I&apos;m a Computer Science Engineering student with a passion for building sleek, user-friendly websites. Leveraging my technical skills and creativity, I offer freelance web development services to bring your digital vision to life.</h2>
    </div>
  )
}

export default About
