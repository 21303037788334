import React from 'react';
import { RxArrowTopRight } from 'react-icons/rx';

const Contact = () => {
  return (
    <div className='w-full flex flex-col items-start pt-16 pb-20'>
        <h1 className='text-[#eeeeee] text-sm tracking-[0.02em]'>Contact</h1>
        <h2 className='text-[#bbbbbb] text-sm mt-1 tracking-[0.01em]'>Let&apos;s Talk About Your Next Project.</h2>
        <div className='flex flex-col w-full mt-3 gap-3'>
            <div className='flex items-center justify-start'>
                <div className='w-[28vw] md:w-[10vw]'>
                    <h1 className='text-[#888888] text-sm tracking-[0.01em]'>Email</h1>
                </div>
                <div className='flex items-start text-[#eeeeee]'>
                    <a className='text-[#eeeeee] tracking-[0.01em] text-sm border-b-[1px] border-transparent hover:border-b-[1px] hover:border-[#eeeeee] transition-all duration-100' href='mailto:support@sakshamtyagi.tech'>support@sakshamtyagi.tech</a>
                    <RxArrowTopRight/>
                </div>
            </div>
            <div className='flex items-center justify-start'>
                <div className='w-[28vw] md:w-[10vw]'>
                    <h1 className='text-[#888888] text-sm tracking-[0.01em]'>Phone</h1>
                </div>
                <div className='flex items-start text-[#eeeeee]'>
                    <a className='text-[#eeeeee] tracking-[0.01em] text-sm border-b-[1px] border-transparent hover:border-b-[1px] hover:border-[#eeeeee] transition-all duration-100' href='tel:+919034256888'>+91 9034256888</a>
                    <RxArrowTopRight/>
                </div>
            </div>
            <div className='flex items-center justify-start'>
                <div className='w-[28vw] md:w-[10vw]'>
                    <h1 className='text-[#888888] text-sm tracking-[0.01em]'>Instagram</h1>
                </div>
                <div className='flex items-start text-[#eeeeee]'>
                    <a className='text-[#eeeeee] tracking-[0.01em] text-sm border-b-[1px] border-transparent hover:border-b-[1px] hover:border-[#eeeeee] transition-all duration-100' href='https://instagram.com/tyagiisaksham' target='blank'>tyagiisaksham</a>
                    <RxArrowTopRight/>
                </div>    
            </div>
            <div className='flex items-center justify-start'>
                <div className='w-[28vw] md:w-[10vw]'>
                    <h1 className='text-[#888888] text-sm tracking-[0.01em]'>Twitter</h1>
                </div>
                <div className='flex items-start text-[#eeeeee]'>
                    <a className='text-[#eeeeee] tracking-[0.01em] text-sm border-b-[1px] border-transparent hover:border-b-[1px] hover:border-[#eeeeee] transition-all duration-100' href='https://twitter.com/tyagiisaksham' target='blank'>tyagiisaksham</a>
                    <RxArrowTopRight/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Contact
