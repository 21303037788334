import React from 'react';
import project1 from '../assets/project1.png';
import project2 from '../assets/project2.png';
import project3 from '../assets/project3.png';

const Projects = () => {
  return (
    <div className='w-full flex flex-col items-start pt-16'>
        <h1 className='text-[#eeeeee] text-sm tracking-[0.02em]'>Selected Projects</h1>
        <h2 className='text-[#bbbbbb] text-sm mt-1 tracking-[0.01em]'>Cool Projects I&apos;ve Worked On.</h2>
        <div className='w-full flex flex-col gap-3 mt-3 '>
            <a href='https://ochi-two.vercel.app/' target='_blank' rel='noreferrer'>
                <div className='h-[20vw] md:h-[12vw] lg:h-[6vw] flex items-center justify-between px-2 gap-4 md:gap-0 py-2 rounded-lg border-[1px] border-[#bbbbbb] border-opacity-15 hover:border-opacity-30 cursor-pointer transition-all duration-100'>
                    <img src={project1} alt='project1' className='h-full rounded-md' />
                    <div className='flex flex-col items-start'>
                        <h1 className='text-[#eeeeee] text-sm'>ochi</h1>
                        <h2 className='text-[#bbbbbb] text-xs'>Modern web design with React JS and Tailwind CSS</h2>
                        <h2 className='text-[#bbbbbb] text-xs font-thin'>2024</h2>
                    </div>
                </div>
            </a>
            <a href='https://gemma-bice.vercel.app/' target='_blank' rel='noreferrer'>
                <div className='h-[20vw] md:h-[12vw] lg:h-[6vw] flex items-center justify-between px-2 gap-4 md:gap-0 py-2 rounded-lg border-[1px] border-[#bbbbbb] border-opacity-15 hover:border-opacity-30 cursor-pointer transition-all duration-100'>
                    <div className='flex flex-col items-start'>
                        <h1 className='text-[#eeeeee] text-sm'>gemma</h1>
                        <h2 className='text-[#bbbbbb] text-xs'>Modern web design with React JS and Tailwind CSS</h2>
                        <h2 className='text-[#bbbbbb] text-xs font-thin'>2024</h2>
                    </div>
                    <img src={project2} alt='project2' className='h-full rounded-md' />
                </div>
            </a>
            <a href='https://vikrantgashwa.vercel.app/' target='_blank' rel='noreferrer'>
                <div className='h-[20vw] md:h-[12vw] lg:h-[6vw] flex items-center justify-between px-2 gap-4 md:gap-0 py-2 rounded-lg border-[1px] border-[#bbbbbb] border-opacity-15 hover:border-opacity-30 cursor-pointer transition-all duration-100'>
                    <img src={project3} alt='project3' className='h-full rounded-md' />
                    <div className='flex flex-col items-start'>
                        <h1 className='text-[#eeeeee] text-sm'>freelance project</h1>
                        <h2 className='text-[#bbbbbb] text-xs'>Portfolio website with React JS and Tailwind CSS</h2>
                        <h2 className='text-[#bbbbbb] text-xs font-thin'>2023</h2>
                    </div>
                </div>
            </a>
        </div>
    </div>
  )
}

export default Projects
